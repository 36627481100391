<template>
   <div id="container">
      <v-card :class="{ card: true, mobile: isMobile }">
         <img
             class="clo"
             @click="closeDialog"
             src="@/assets/btn_close.png"
             alt=""
         />
         <div class="top ">
            <h3 v-if="complaintType == 'commonComplaint'">{{$t("popupNftComplaintsNFTComplaint")}}</h3>
              <h3 v-else-if="complaintType == 'folderComplaint'">{{$t("popupFolderComplaints")}}</h3> 
            <h3 v-else>{{$t("popupCollectComplaintsNFTComplaint")}}</h3>
            <div class="content d-flex flex-lg-row flex-column mt-lg-1">
               <img :src="Imgsrc" alt=""/>
               <div class="rgh ">
                  <h4>{{NftName}}</h4>
               </div>
            </div>
         </div>
         <div class="labstatus">
            <span class="title">{{$t("popupNftComplaintsComplaintsReasons")}}</span>
            <div class="lab">
               <div
                   v-for="(item, index) in tagList"
                   :key="index"
                   :class="inputTagList.indexOf(item) > -1 ? 'active' : ''"
                   @click="tagChange(item)"
                   href="#"
               >{{ item }}
               </div
               >
            </div>
         </div>
         <div class="ti-tx">
            <span class="title">{{$t("popupAccountAppealDescription")}}</span>
            <v-textarea
                class="txt"
                label=""
				 :placeholder="this.$t('popupAccountAppealPleaseDescribe')"
                v-model="firsttxt"
                outlined
                rows="5"
                ref="firsttxt"
                :rules="[rules.textRules]"
                row-height="18"
                counter="500"
            ></v-textarea>
         </div>
         <div style="margin-left: 52px" class="upload">
            <div style="display: flex;">
               <div class="uploadfile" v-for="(item,index) in uploadfile" :key="index">
                  <img class="img" :src="item"/>
                  <img class="icon" src="@/assets/btn_close.png" @click="imgRemove(index)"/>
               </div>

               <div class="uploadfile" v-if="uploadfile.length<3">
                  <FIleInput class="FIleInput" @change="change" :key="fileInputKey" accept=".jpg,.jpeg,.png,.gif">
                     <v-icon color="black">mdi-plus</v-icon>
                  </FIleInput>
               </div>
            </div>
            <span class="file-limit">{{$t("popupNftAppealLimit")}}</span>
         </div>
         <div class="emailinp">
            <span class="title">{{$t("popupNftAppealEmail")}}</span>
            <v-textarea
                label=""
                ref="emailValue"
                v-model="emailValue"
                :rules="[rule.emailRules, rule.email]"
                auto-grow
                outlined
                rows="2"
                row-height="18"
            ></v-textarea>
         </div>
         <div class="other">
            <span class="title">{{$t("popupAccountComplaintsComplainant")}}</span>
            <div class="tine">
               <span class="name">{{complainantName}}</span>
               <span class="tinecont">{{ tineValue  | didfilter }}</span>
            </div>
         </div>
         <div class="checkbox">
            <span class="title">{{$t("popupNftComplaintsOriginalCreator")}}</span>
            <v-sheet class="switchs">
               <v-switch v-model="switch2" inset></v-switch>
            </v-sheet>
         </div>
         <div class="footer">
            <v-btn class="sub" submit @click="subCommit">{{$t("popupTransferCommit")}}</v-btn>
         </div>
      </v-card>
      <PromptBox ref="promptBox" @commit="promptBoxCommit"></PromptBox>
       <uComponents  ref="ucom"></uComponents>
   </div>
</template>

<script>
  import api from "@/api";
  import FIleInput from "@/components/FIleInput.vue";
  import { getFileSrc } from "@/utils/file";
  import PromptBox from "@/components/PromptBox.vue";

  const UPLOAD_KEY = "UPLOAD";
  const WORK_KEY = "WORK";
  //NFT投诉
  export default {
    components: { FIleInput, PromptBox },
    name: "nftComplaints",
    props: {
      Nft_sku: {
        type: Object
      },
      complaintType:{
         type:String
      }
    },
    data: function() {
		return{
      tagList: [
       this.$t("complain_reason_type1"),
       this.$t("complain_reason_type2"),
       this.$t("complain_reason_type3"),
       this.$t("complain_reason_type4"),
       this.$t("complain_reason_type5"),
       this.$t("complain_reason_type6")
      ],
      inputTagList: [],
      newOpen: true,
      firsttxt: "",
      emailValue: "",
      tineValue: "",
      switch2: false,
      Imgsrc: "",
      NftName: "",
      complainantName:'',

      uploadfile: [],
      imgUrl: [],
      fileInputKey: 0,
      rules: {
        textRules: value => value.length <= 500 || "Max 500 characters."
      },
      rule: {
        emailRules: value => !!value || 'Email is required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
	  }
    },
    computed: {
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }

    },
    filters: {
      didfilter: function(value) {
        if (value && value.length > 12) {
          return value.substr(0, 10) + "..." + value.substr(-8);
        } else {
          return "";
        }
      }
    },
    async mounted() {
       console.log("wxl --- wew",this.Nft_sku)
       window.eventBus.$on('LangChange', this.onLangChange);

      if (this.Nft_sku.imgUrl != null) {
        this.Imgsrc = await getFileSrc(WORK_KEY, this.Nft_sku.imgUrl);
      }

      this.NftName = this.Nft_sku.name;

      let param = { userAddress: this.$store.state.did };
	   let res = await api.home.createInfo(param);
      //this.complainantName = this.Nft_sku.issuerName;
      this.tineValue = this.$store.state.did;
      this.complainantName = res.data.name;
      // this.tineValue = this.Nft_sku.owner;

    },
    methods: {
      verify() {
        let emailVer = this.$refs.emailValue.validate(true);
        let textVer = this.$refs.firsttxt.validate(true);
        !emailVer ? this.$refs.emailValue.focus() : "";
        !textVer ? this.$refs.firsttxt.focus() : "";
        
        return emailVer && textVer;
      },
      async subCommit() {

        if(!this.verify()) {
          return;
        }
        if(this.inputTagList.length == 0) {
            this.$toast("error", this.$t("popupNftComplaintsComplaintsReasons2"));
            return;
        }
        
        //xxl 451 bug 
        if(this.inputTagList.length == 0){
           this.$toast("error", this.$t("complaintsAtLestOneReasons"));
           return;
        }

        let identitys = 1;
        if (this.switch2) {
          identitys = 1;
        } else {
          identitys = 2;
        }
        debugger
        let params = {
          complaintType: 3,
          complainants: this.$store.state.did,
          complaintCause: encodeURIComponent(this.inputTagList),
          content: encodeURIComponent( this.firsttxt ),
          email: this.emailValue,
          respondent:this.Nft_sku.owner,
          identity: identitys,
          picture: this.imgUrl.join(",")
        };
         if(this.complaintType == 'commonComplaint'){
            if(this.Nft_sku.collectionType == 0){
             params.contractId = this.Nft_sku.contractId
            }else if(this.Nft_sku.collectionType == 1){
               if(this.Nft_sku.tokenId){
                    params.tokenId = this.Nft_sku.tokenId
               }else{
                    params.contractCollectionId = this.Nft_sku.contractCollectionId
               }
                
            }else{
                  params.contractCollectionId = this.Nft_sku.contractCollectionId
            }
         

         }else if(this.complaintType == 'folderComplaint'){
            params.contractCollectionId = this.Nft_sku.contractCollectionId
         }
         else{
             params.nftAddress = this.Nft_sku.nftAddress
         }
        let res = await api.home.nftComplaint(params);
        if (res.success == true) {
               this.$toast("success", this.$t("success")).then(() => {

            this.closeDialog();
        });
        }else{
            this.$toast('error',res.msg)
        }
       
      },
      promptBoxCommit() {
          this.closeDialog();
      },
      //关闭弹框，发送open值
      closeDialog() {
        this.newOpen = false;
        this.$emit("getOpen", this.newOpen);
      },
      //标签选中
      tagChange(item) {
        let Index = this.inputTagList.indexOf(item);
        if (Index > -1) {
          //选中，再次点击会取消
          this.inputTagList.splice(Index, 1);
        } else {
          //点击选中
          this.inputTagList.push(item);
        }
      },
      async change(file) {
         let ft = file.name.substr(file.name.lastIndexOf(".") + 1);
      if ("JPG,JPEG,PNG,GIF".indexOf(ft.toUpperCase()) == -1) {
        this.$toast("error", this.$t("file_type_not_support"));
        this.fileInputKey++;
        return;
      }
       if (file.size > 10242880) {
          this.$toast("error", this.$t("image_not_support"));
          return;
        }

         let path = await api.image.uploadImage(file);
        let  imageUrl = path.data.data;
		  this.fileInputKey++;
		  let url = this.getImageIpfs(imageUrl);
        this.uploadfile.push(url);
        this.imgUrl.push(imageUrl);
        
   

      },

      imgRemove(idx) {
        this.uploadfile.splice(idx, 1);
      },
       onLangChange() {
         this.tagList = [
            this.$t("complain_reason_type1"),
            this.$t("complain_reason_type2"),
            this.$t("complain_reason_type3"),
            this.$t("complain_reason_type4"),
            this.$t("complain_reason_type5"),
            this.$t("complain_reason_type6")
         ];
       }
    }
  };
</script>

<style lang="scss" scoped>
   .card {
      display: flex;
      flex-direction: column;
      min-height: 769px;
      width: 600px;

      .clo {
         position: absolute;
         right: 10px;
         top: 10px;
         width: 30px;
         height: 31px;
         background-color: #fff;
         border-radius: 50%;
         margin-right: 0;
         cursor: pointer;
      }

      .top {
         display: flex;
         flex-direction: column;

         h3 {
            margin: 41px 0 17px 52px;
            font-weight: bold;;
            font-size: 25px;
            color: #270645;
         }

         .content {
            display: flex;
            flex-direction: row;
            img{
               width: 80px;
               height: 80px;
            }
            .rgh {
               display: flex;
               flex-direction: column;

               .ic-sp {
                  display: flex;

                  .icon {
                     display: flex;
                     align-items: flex-end;
                     width: 41px;
                     height: 40px;
                     background-color: #6f58d9;
                     border-radius: 50%;
                     margin-left: 0;
                     margin-top: 10px;
                  }
               }

               h4 {
                  font-weight: bold;;
                  font-size: 20px;
                  color: #270645;
                  margin-bottom: 10px;
               }
            }
         }
      }

      .labstatus {
         margin: 0 52px;
         .title{
               font-family: Helvetica !important;
            font-size: 15px !important;
            font-weight: bold !important;
         }
         .lab {
            font-family: Helvetica;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;

            div {
               display: inline-block;
               margin-right: 9px;
               margin-bottom: 9px;
               width: auto;
               height: 40px;
               padding: 0 16px;
               background-image: linear-gradient(#ffffff, #ffffff),
               linear-gradient(#f8f6fd, #f8f6fd);
               background-blend-mode: normal, normal;
               border-radius: 5px;
               border: solid 2px #e3e3e3;
               line-height: 40px;
               text-align: center;
               cursor: pointer;
            }

            .active {
              border:2px solid #7800f4
            }
         }
      }

      .ti-tx {
         margin: 10px 52px;

         .txt {
            width: 500px;
            height: 166px;
            background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#f8f6fd, #f8f6fd);
            background-blend-mode: normal, normal;
         }
          .title{
               font-family: Helvetica !important;
            font-size: 15px !important;
            font-weight: bold !important;
         }

      }

      .upload {
         .uploadfile {
            margin-bottom: 14px;
            width: 101px;
            height: 101px;
            background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#f8f6fd, #f8f6fd);
            background-blend-mode: normal, normal;
            border-radius: 1px;
            border: solid 2px #e3e3e3;

            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-right: 30px;
            cursor: pointer;

            .FIleInput {
               width: 100%;
               height: 100%;

               .v-icon {
                  width: 100%;
                  height: 100%;
                  padding: 25px;
               }

            }

            .icon {
               width: 20px;
               height: 20px;
               border-radius: 50%;
               position: absolute;
               top: -10px;
               left: 30px;
               background-color: #fff;
               z-index: 99;
               cursor: pointer;
            }

            .img {
               width: 100%;
               height: 100%;
               margin: 0;
            }
         }

         .file-limit {
            // width: 137px;
            height: 13px;
            font-family: Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #766983;
         }
      }

      .emailinp {
         margin: 10px 52px;
          .title{
               font-family: Helvetica !important;
            font-size: 15px !important;
            font-weight: bold !important;
         }
      }

      .other {
         margin: -20px 52px 25px;
          .title{
               font-family: Helvetica !important;
            font-size: 15px !important;
            font-weight: bold !important;
         }
         .tine {
            span {
               display: inline-block;
            }

            .tinecont {
               position: absolute;
               right: 52px;
            }
         }
      }

      .checkbox {
         margin: 0 52px 24px;

         .title {
            font-size: 15px !important;
         }

         .switchs {
            display: inline-block;
            position: absolute;
            right: 55px;
            bottom: 160px;
            width: 60px;
            height: 10px;
            // background-color: #e3e3e3;
            border-radius: 18px;
         }
      }

      .footer {
         display: flex;
         flex-direction: column;
         text-align: center;
         margin-bottom: 50px;

         .sub {
            margin: 0 auto;
            width: 450px;
            height: 51px;
             background-image: linear-gradient(
                    90deg,
                    #d300fd 0%,
                    #a439fe 26%,
                    #7471ff 52%,
                    #00fdcf 100%
            );
            border-radius: 25px;
            opacity: 0.9;
            color: #fff;
            font-weight: bold;;
            font-size: 20px;
         }
      }

      &.mobile {
         .top{
            .content{
               .rgh{
                  margin-left: 50px;
               }
               
            }
         }
         .footer {
            .sub {
               width: 80%;
            }
         }
         .other{
            .tine{
               .name{
                  width:50px;
                   overflow: hidden;
  
       white-space: nowrap;
       text-overflow: ellipsis;
               }
            }

         }
      }
   }

   img {
      display: inline-block;
      width: 126px;
      height: 126px;
      border-radius: 5px;
      margin: 1px 22px 10px 52px;
   }

   span {
      display: flex;
      align-items: center;
      font-family: Helvetica;
      color: #270645;
   }

   .title {
      font-weight: bold;;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      color: #270645;
   }
   
</style>


